<template>
  <div class="newQuestion">
    <template>
      <el-form ref="form" label-width="180px">
        <el-form-item required>
          <span slot="label">
            Test
          </span>
          <el-radio-group v-model="MockType" @change="changeTest">
            <el-radio
              v-for="(test, index) in Tests"
              :key="index"
              :label="test.id"
              :value="test.id"
              :disabled="isEdit"
              border
            >
              <span class="test-color">{{ test.name }}</span>
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item required>
          <span slot="label">
            Module
          </span>
          <div>
            <el-radio-group
              v-model="question.subject_id"
              @change="(newVal) => changeSubject(newVal, question.subject_id)"
            >
              <el-radio
                v-for="(subject, index) in subjectsObj[MockType]"
                :key="index"
                :label="subject.id"
                :value="subject.id"
                border
              >
                <span>{{ subject.name }}</span>
              </el-radio>
            </el-radio-group>
            <el-alert
              title="Switching Module will clear all selected tags."
              type="warning"
              :closable="false"
              show-icon
              style="line-height: 1rem;"
            >
            </el-alert>
          </div>
        </el-form-item>
      </el-form>
    </template>
    <!-- 单次输入 -->
    <template>
      <el-form label-width="180px">
        <el-form-item>
          <div slot="label">
            {{ $t("AddQuestions.Labels") }}
            <router-link :to="{ name: 'QuestionTags' }"
              ><i class="fa fa-edit"></i
            ></router-link>
          </div>
          <el-select
            v-model="question.question_tag_ids"
            multiple
            filterable
            style="width:100%"
          >
            <el-option
              v-for="tag in tags"
              :key="tag.id"
              :label="`[${tag.type}] - ${tag.name}`"
              :value="tag.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item required v-if="false">
          <span slot="label">
            {{ $t("AddQuestions.Type") }}
          </span>
          <el-radio-group v-model="question.type">
            <el-radio label="single" border>
              {{ $t("AddQuestions.Multiple Choice") }}
            </el-radio>
            <el-radio label="math" border>
              {{ $t("AddQuestions.Grid-In") }}
            </el-radio>
            <!-- <el-radio label="math_range" border>
              {{ $t("AddQuestions.Grid-In") }}
            </el-radio> -->
          </el-radio-group>
        </el-form-item>
      </el-form>
      <hr />
      <div class="row view-question-detail">
        <div class="col-md-12 col-lg-6 mb-4 questions-content">
          <el-card>
            <h5>
              Passage
            </h5>
            <hr />
            <AddPassageModal
              :isEdit="isEdit"
              :passage="passage"
              :questionId="question.passage_id"
              :isNew="true"
              @setPassage="setPassage"
              @selectPassage="selectPassage"
            />
          </el-card>
        </div>
        <div class="col-md-12 col-lg-6">
          <AddQuestionModal
            :MockType="MockType"
            :passage="passage"
            :question="question"
            :tags="tags"
            @setQuestion="setQuestion"
          ></AddQuestionModal>
        </div>
      </div>
      <!-- <hr /> -->
      <PreviewQuestionSAT
        v-if="MockType === 4"
        class="mt-4"
        :showPassage="passage"
        :showQuestion="question"
      >
      </PreviewQuestionSAT>

      <PreviewQuestionACT
        v-if="MockType === 1"
        class="mt-4"
        :showPassage="passage"
        :showQuestion="question"
      >
      </PreviewQuestionACT>
      <!-- <hr /> -->
      <div class="text-center mt-4">
        <el-button v-if="isEdit" type="success" @click="saveAll('back')">
          <i class="fas fa-save"></i>
          {{ $t("AIQuestions.Save") }}
        </el-button>
        <template v-else>
          <el-button type="success" @click="saveAll('back')">
            <i class="fas fa-plus"></i>
            Add
          </el-button>
          <el-button type="success" @click="saveAll('addNewQuestion')">
            <i class="fas fa-plus"></i>
            {{ $t("AIQuestions.Save and Create Another") }}
          </el-button>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import AddQuestionModal from "@/views/questions/add/components/AddQuestion.vue";
import AddPassageModal from "@/views/questions/add/components/PassageForm";
import PreviewQuestionSAT from "@/views/questions/add/components/PreviewQuestionSAT";
import PreviewQuestionACT from "@/views/questions/add/components/PreviewQuestionACT";
import Add from "@/views/questions/apis/add";
import SAT from "@/apis/sat.js";
import Common from "@/mixins/common.js";

export default {
  components: {
    AddQuestionModal,
    AddPassageModal,
    PreviewQuestionSAT,
    PreviewQuestionACT
  },

  mixins: [Common],

  props: [],
  data() {
    return {
      MockType: 4,
      Tests: [
        {
          id: 4,
          name: "SAT"
        },
        {
          id: 1,
          name: "ACT"
        }
      ],
      subjectsObj: {
        // ACT
        1: [
          {
            id: 1,
            name: "English"
          },
          {
            id: 2,
            name: "Math"
          },
          {
            id: 3,
            name: "Reading"
          },
          {
            id: 4,
            name: "Science"
          }
        ],
        // SAT
        4: [
          {
            id: 13,
            name: "English"
          },
          {
            id: 14,
            name: "Math"
          }
        ]
      },
      companies: [],
      smartOneLoading: false,
      showProgress: false,
      progress: 0,
      tags: [],
      smartTags: null,
      defaultTextarea: "",
      mathTextarea: "",
      smartType: "default",
      smartQuestionsText: "",
      smartMathQuestionsText: "",
      smartQuestions: [],
      inputType: 1,
      textarea: "",
      sat: {
        is_new: 1,
        title: ""
      },
      sats: [],
      title: 0,
      showCreateNewPassagesDialog: false,
      showCreateSATDialog: false,
      question: {
        subject_id: null,
        question_set_id: null,
        passage_id: null,
        order: 0,
        type: "single",
        content: "",
        difficulty: 1,
        metadata: null,
        question_tag_ids: [],
        options: [
          {
            order: 1,
            label: "A",
            content: ""
          },
          {
            order: 2,
            label: "B",
            content: ""
          },
          {
            order: 3,
            label: "C",
            content: ""
          },
          {
            order: 4,
            label: "D",
            content: ""
          }
        ],
        answers: [{ answer: [""] }],
        explanations: [{ explanation: "" }]
      },
      passage: {
        title: "",
        introduction: "",
        content: ""
      }
    };
  },
  computed: {
    ...mapGetters("user", ["getProfile"]),
    questionId() {
      return this.$route.params.questionId;
    },
    isEdit() {
      return this.questionId && this.questionId > 0;
    }
  },
  watch: {},

  async mounted() {
    // 如果存在问题ID且大于0，说明是编辑模式
    if (this.questionId && this.questionId > 0) {
      const res = await Add.getQuestionDetail(this.questionId);
      const question = res;
      this.MockType = res.subject.test_id;
      this.question = { ...this.question, ...question };
      this.passage = { ...this.passage, ...question.passage };
      if (this.question.explanations.length < 1) {
        this.question.explanations = [{ explanation: "" }];
      }

      if (question.question_tags && question.question_tags.length > 0) {
        question.question_tags.forEach(e => {
          this.question.question_tag_ids.push(e.id);
        });
      }
    } else {
      // 如果是新建模式,设置默认科目ID
      this.question.subject_id = this.subjectsObj[this.MockType][0].id;
    }
    this.getTags();
  },
  methods: {
    // 切换考试类型（SAT/ACT）
    changeTest() {
      this.question.subject_id = this.subjectsObj[this.MockType][0].id;
      this.getTags();
      this.question.question_tag_ids = [];
    },

    // 切换科目时重新获取标签
    async changeSubject(newVal, subjectId) {
      console.log(newVal, subjectId);
      this.getTags();
      this.question.question_tag_ids = [];
      // try {
      //   await this.$confirm(
      //     "Switching subjects will clear all selected tags. Do you want to continue?",
      //     "Warning",
      //     {
      //       confirmButtonText: "Yes",
      //       cancelButtonText: "No",
      //       type: "warning"
      //     }
      //   );
      //   this.getTags();
      //   this.question.question_tag_ids = [];
      // } catch (err) {
      //   // User clicked No, do nothing
      //   this.question.subject_id = subjectId;
      //   return;
      // }
    },

    // 根据考试类型和科目获取标签列表
    async getTags() {
      const res = await Add.getTags({
        sat_id: this.MockType,
        subject_id: this.question.subject_id
      });
      this.tags = res.data;
    },

    // 更新问题数据
    setQuestion(value) {
      this.question = { ...this.question, ...value };
    },

    // 更新文章数据
    setPassage(value) {
      this.passage = { ...this.passage, ...value };
    },

    // 选择已有文章
    selectPassage(passage) {
      this.question.passage_id = passage.id;
      this.passage = passage;
      this.showCreateNewPassagesDialog = false;
    },

    // 保存所有数据（包括文章和问题）
    async saveAll(type) {
      await this.saveNewPassage();

      if (this.isEdit) {
        await this.updateQuestion();
      } else {
        await this.saveQuestion();
      }
      if (type === "back") {
        // this.$router.go(-1);
      } else {
        let box = document.getElementsByClassName("right-content")[0];
        box.scrollTo(0, 0);
      }
    },

    // 保存新文章或更新现有文章
    async saveNewPassage() {
      if (this.passage.content && this.passage.content !== "") {
        if (this.question.passage_id && this.question.passage_id > 0) {
          await Add.updatePassage(this.question.passage_id, {
            ...this.passage
          });
        } else {
          const res = await Add.createPassage({
            ...this.passage
          });
          this.question.passage_id = res.id;
        }
      }
    },

    // 保存新问题
    async saveQuestion() {
      const res = await Add.createQuestion({
        ...this.question
      });
      this.$message({
        message: "Success",
        type: "success"
      });
      // 重置问题和文章数据为初始状态
      this.question = {
        ...this.question,
        passage_id: null,
        content: "",
        difficulty: 1,
        question_tag_ids: [],
        options: [
          {
            order: 1,
            label: "A",
            content: ""
          },
          {
            order: 2,
            label: "B",
            content: ""
          },
          {
            order: 3,
            label: "C",
            content: ""
          },
          {
            order: 4,
            label: "D",
            content: ""
          }
        ],
        answers: [{ answer: [""] }],
        explanations: [{ explanation: "" }]
      };
      this.passage = {
        title: "",
        introduction: "",
        content: ""
      };
    },

    // 更新现有问题
    async updateQuestion() {
      await Add.updateQuestion(this.questionId, {
        ...this.question
      });
      this.$message({
        message: "Success!",
        type: "success"
      });
    }
  }
};
</script>

<style scoped>
.container {
  background-color: white;
  padding-top: 0;
  padding: 40px 40px 20px 40px;
  margin-top: 40px;
  margin-bottom: 40px;
}
::v-deep .el-alert__description {
  font-size: 14px;
}
::v-deep .el-dialog__header {
  background: #efefef;
  padding-bottom: 20px;
}
::v-deep .el-dialog__footer {
  background: #efefef;
  padding-top: 20px;
}
::v-deep .add-passage-dialog .el-dialog__body {
  height: 600px;
  overflow: auto;
}
::v-deep .smartText .ck-content {
  min-height: 400px;
  max-height: 800px;
}
::v-deep .smartEditor .ck-content {
  min-height: 200px;
  /* max-height: 600px; */
}
::v-deep .smartEditor .ck-content p {
  margin: 0;
}
/* ::v-deep .smartEditor .ck-content p {
  margin: 0;
  padding: 1rem 0;
  border-top: 1px solid var(--themeColor);
}
::v-deep .smartEditor .ck-content p:first-child {
  border: none;
} */
::v-deep .smartEditor .ck-content p {
  margin: 0 0.5rem;
}
::v-deep .smartEditor .ck-content p:first-child {
  margin-top: 1rem;
}
</style>
